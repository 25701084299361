// This file is used to define reducers for the slice, and has no exports
/* eslint-disable import/no-unused-modules */
import {
  clearV2DirtyState,
  markPageLoadV2Apis,
  clearResponseV2Api,
  setV2TestProfile,
  toggleShowTestData,
  updateTestDataForBlock,
  resetTestDataForBlock,
  clearApiErrorsAndMetas,
  resetApis,
  setBlockCodeFormatting,
  fetchApiPermissionsSuccess,
} from "./actions";
import slice, { StepRunTestData, TestDataType } from "./slice";
slice.reducer(clearV2DirtyState, (state, { payload }) => {
  delete state.meta[payload.id].dirty;
});

slice.reducer(markPageLoadV2Apis, (state, action) => {
  action.payload.forEach((id) => {
    state.loading[id] = true;
  });
});

slice.reducer(clearResponseV2Api, (state, { payload }) => {
  state.meta[payload.id] = {
    ...state.meta[payload.id],
    executionResult: undefined,
    enrichedExecutionResult: undefined,
    editedSinceLastExecution: undefined,
  };
});

slice.reducer(setV2TestProfile, (state, { payload }) => {
  state.meta[payload.apiId].testProfile = payload.profile;
});

slice.reducer(toggleShowTestData, (state, { payload }) => {
  state.meta[payload.apiId].showTestDataForBlock = {
    ...state.meta[payload.apiId].showTestDataForBlock,
    [payload.blockName]:
      !state.meta[payload.apiId].showTestDataForBlock?.[payload.blockName],
  };
});

slice.reducer(updateTestDataForBlock, (state, { payload }) => {
  if (!state.meta[payload.apiId]) return;
  const existingValues =
    state.meta[payload.apiId]?.testDataForBlock?.[payload.blockName];
  const updatedValues = Object.entries(payload.values).reduce(
    (acc, [key, value]) => {
      acc[key] = {
        value,
        type: existingValues?.[key]?.type ?? TestDataType.EXTERNAL,
        isDirty: true,
        initialValue: existingValues?.[key]?.initialValue ?? "",
      };
      return acc;
    },
    {} as StepRunTestData,
  );
  state.meta[payload.apiId].testDataForBlock = {
    ...state.meta[payload.apiId]?.testDataForBlock,
    [payload.blockName]: {
      ...state.meta[payload.apiId]?.testDataForBlock?.[payload.blockName],
      ...updatedValues,
    },
  };

  // clear errors
  Object.keys(payload.values).forEach((key) => {
    delete state?.meta?.[payload.apiId]?.testDataEvaluationError?.[
      payload.blockName
    ]?.fieldErrors?.[key];
  });
});

slice.reducer(resetTestDataForBlock, (state, { payload }) => {
  const existingField =
    state?.meta?.[payload.apiId]?.testDataForBlock?.[payload.blockName]?.[
      payload.fieldToReset
    ];
  if (!existingField) return;
  existingField.value = undefined;
  existingField.isDirty = false;
  // clear errors
  delete state?.meta?.[payload.apiId]?.testDataEvaluationError?.[
    payload.blockName
  ]?.fieldErrors?.[payload.fieldToReset];
});

slice.reducer(clearApiErrorsAndMetas, (state) => {
  state.errors = {};
  state.meta = {};
});

slice.reducer(resetApis, (state) => {
  state.entities = {};
  state.errors = {};
  state.meta = {};
  state.loading = {};
});

slice.reducer(setBlockCodeFormatting, (state, { payload }) => {
  if (
    !state.formatting[payload.apiId] ||
    !state.entities[payload.apiId] ||
    state.meta[payload.apiId]?.isDeleting
  ) {
    return;
  }

  state.formatting[payload.apiId].blocks[payload.blockName] = {
    status: payload.status,
    error: payload.error,
  };
});

slice.reducer(fetchApiPermissionsSuccess.type, (state, { payload }) => {
  state.permissions[payload.apiId] = payload.permissions;
});
