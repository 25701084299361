import { ReduxAction } from "legacy/constants/ReduxActionConstants";
import { setCreatingCustomEvent } from "store/slices/application/events/eventActions";
import { setCreatingTimer } from "store/slices/application/timers/timerActions";
import { createImmerReducer } from "../createReducer";

type NavigationEntityState = {
  creatingTimer: boolean;
  creatingEvent: boolean;
};

const initialState: NavigationEntityState = {
  creatingEvent: false,
  creatingTimer: false,
};

const navigationEntityReducer = createImmerReducer<NavigationEntityState>(
  initialState,
  (builder) =>
    builder
      .addCase(setCreatingTimer, (state, { payload }: ReduxAction<boolean>) => {
        return {
          ...state,
          creatingTimer: payload,
        };
      })
      .addCase(
        setCreatingCustomEvent,
        (state, { payload }: ReduxAction<boolean>) => {
          return {
            ...state,
            creatingEvent: payload,
          };
        },
      ),
);

export default navigationEntityReducer;
