import React from "react";
import styled from "styled-components";
import ErrorComponent from "components/app/Error/ErrorComponent";
import { API_STATUS_CODES } from "legacy/constants/ApiConstants";

const Wrapper = styled.div`
  text-align: center;
  .bold-text {
    font-weight: ${(props) => props.theme.legacy.fontWeights[3]};
    font-size: 24px;
  }
  .page-unavailable-img {
    width: 35%;
  }
  .button-position {
    margin: auto;
  }
  height: 100%;
`;

const PageNotFound = ({
  title = "Content not found",
  errorMessage = "We couldn’t find the page you were looking for. Make sure that you have the right URL. ",
  buttonPath,
  buttonText,
  onButtonClick,
  hideActions,
}: {
  title?: string;
  errorMessage?: string | React.ReactElement;
  buttonPath?: string;
  buttonText?: string;
  onButtonClick?: () => void;
  hideActions?: boolean;
}) => {
  return (
    <Wrapper>
      <ErrorComponent
        errorCode={API_STATUS_CODES.RESOURCE_NOT_FOUND}
        title={title}
        errorMessage={errorMessage}
        hideActions={hideActions}
        buttonPath={buttonPath}
        buttonText={buttonText}
        mainButtonType={"ghost"}
        handleButtonClick={onButtonClick}
      />
    </Wrapper>
  );
};

export default PageNotFound;
