import { decodeBytestrings } from "@superblocksteam/shared";
import { sendErrorUINotification } from "utils/notification";
import * as BackendTypes from "../backend-types";
import { ControlFlowFrontendDSL } from "../control-flow/types";
import ExecutionEventProcessor from "./ExecutionEventProcessor";
import type {
  EnrichedExecutionProcessingOptions,
  EnrichedExecutionResult,
  ExecutionResponse,
} from "../types";
import type { SharedExecutionOutput } from "store/slices/apisShared";

export const decodeBytestringsInV2ExecutionResponse = (
  response: BackendTypes.ApiV2ExecutionResponse,
) => {
  if (response?.output?.result != null) {
    response.output.result = decodeBytestrings(response.output.result, false);
  }
  if (Array.isArray(response.events)) {
    response.events.forEach((event) => {
      const typedEvent = event as BackendTypes.ExecutionEvent;
      if (typedEvent?.end?.output?.result != null) {
        typedEvent.end.output.result = decodeBytestrings(
          typedEvent.end.output.result,
          false,
        );
      }
    });
  }
};

const totalExecutionTime = (response?: BackendTypes.ApiV2ExecutionResponse) => {
  if (!response) {
    return 0;
  }
  const firstEvent = response?.events?.[0] as BackendTypes.ExecutionEvent;
  const lastEvent = response?.events?.[
    response?.events?.length - 1
  ] as BackendTypes.ExecutionEvent;
  const startTime = firstEvent?.timestamp;
  const endTime = lastEvent?.timestamp;
  if (!startTime || !endTime) {
    return 0;
  }

  return new Date(endTime).valueOf() - new Date(startTime).valueOf();
};

const mergeExecutionResults = (
  prevExecutionResult: EnrichedExecutionResult,
  newExecutionResult: EnrichedExecutionResult,
) => {
  return {
    // For top-level results, default to the PREVIOUS result
    apiResult: prevExecutionResult.apiResult ?? newExecutionResult.apiResult,
    executionError:
      prevExecutionResult.executionError ?? newExecutionResult.executionError,
    // For block-level results, overwrite the PREVIOUS result
    blockInfos: {
      ...prevExecutionResult?.blockInfos,
      ...newExecutionResult?.blockInfos,
    },
    lastOutputs: {
      ...prevExecutionResult.lastOutputs,
      ...newExecutionResult.lastOutputs,
    },
    orderedExecutions: (prevExecutionResult.orderedExecutions ?? []).concat(
      newExecutionResult.orderedExecutions ?? [],
    ),
  };
};
export const getEnrichedExecutionResult = (
  response: undefined | ExecutionResponse,
  controlFlow: ControlFlowFrontendDSL,
  // When running a single block, we want to keep the previous execution result for the rest of the blocks
  previousExecutionResult?: EnrichedExecutionResult,
  options?: EnrichedExecutionProcessingOptions,
): EnrichedExecutionResult => {
  if (response && "systemError" in response) {
    return response;
  }
  const handleUnknownBlockEvent = (event: BackendTypes.ExecutionEvent) => {
    if (event.end?.error != null && options?.sendErrorOnUnknownBlockErrors) {
      sendErrorUINotification({
        message: "Execution error",
        description: event.end.error.message,
      });
    }
  };

  const EventProcessor = new ExecutionEventProcessor(
    controlFlow,
    options?.blocksToReinitialize,
    handleUnknownBlockEvent,
  );
  response?.events?.forEach((event) => {
    EventProcessor.processEvent(event as BackendTypes.ExecutionEvent);
  });
  const blockInfos = EventProcessor.getBlockInfos();
  const orderedExecutions = EventProcessor.getOrderedExecutions();
  const lastOutputs = EventProcessor.computeLastOutputs();

  const unhandledErrors =
    response?.errors?.filter((error) => !error?.handled) ?? [];

  const executionError = unhandledErrors.length
    ? unhandledErrors.reduce((accum: string, error) => {
        return accum + `${accum === "" ? "" : ", "}${error.message ?? "Error"}`;
      }, "")
    : undefined;

  const apiResult: SharedExecutionOutput = {
    output: response?.output?.result,
    executionTime: totalExecutionTime(response),
    log: [],
    request: "",
  };

  const executionResult = {
    apiResult,
    blockInfos,
    executionError,
    lastOutputs,
    orderedExecutions,
  };

  if (previousExecutionResult) {
    return mergeExecutionResults(previousExecutionResult, executionResult);
  }
  return executionResult;
};

/*
  This function converts an array of stream events into a single execution response.
*/
export const parseStreamResult = (
  streamResult: Array<BackendTypes.StreamEvent>,
  options?: {
    includeFinalOutput?: boolean;
  },
): undefined | BackendTypes.ApiV2ExecutionResponse => {
  if (!streamResult || !Array.isArray(streamResult) || !streamResult.length) {
    return;
  }
  const execution = streamResult[0].result.execution;
  const [errors, events] = streamResult.reduce(
    (accum: [{ message: string }[], BackendTypes.ExecutionEvent[]], event) => {
      if (event.result.event.end && event.result.event.end.error) {
        accum[0].push(event.result.event.end.error);
      }
      accum[1].push(event.result.event);

      return accum;
    },
    [[], []],
  );

  const result: BackendTypes.ApiV2ExecutionResponse = {
    execution,
    events,
    errors,
    status: "STATUS_COMPLETED",
  };

  if (options?.includeFinalOutput) {
    // Check for a Return block, which should be the api result rather than just the last block
    let returnBlockOutput: BackendTypes.ApiV2ExecutionResponse["output"];
    for (let i = 0; i < streamResult.length; i++) {
      const streamEvent = streamResult[i];
      if (
        streamEvent.result.event.type === "BLOCK_TYPE_RETURN" &&
        streamEvent.result.event.end
      ) {
        returnBlockOutput = streamEvent.result.event.end
          ?.output as BackendTypes.ApiV2ExecutionResponse["output"];
        break;
      }
    }
    if (returnBlockOutput) {
      result.output = returnBlockOutput;
    } else {
      const output = streamResult[streamResult.length - 1].result.event.end
        ?.output as BackendTypes.ApiV2ExecutionResponse["output"];
      result.output = output;
    }
  }

  return result;
};

const moveInRecord = <T>(
  rec: Record<string, T>,
  oldKey: string,
  newKey: string,
) => {
  const obj = rec[oldKey];
  delete rec[oldKey];
  rec[newKey] = obj;
};

// move things within the enriched execution result to accommodate things like
// blocks being renamed/deleted
export const executionResponseManipulators = {
  renameBlock: (
    result: EnrichedExecutionResult,
    params: {
      oldName: string;
      newName: string;
    },
  ) => {
    result.blockInfos &&
      moveInRecord(result.blockInfos, params.oldName, params.newName);
    result.lastOutputs &&
      moveInRecord(result.lastOutputs, params.oldName, params.newName);

    result.orderedExecutions &&
      result.orderedExecutions.forEach((exec) => {
        if (exec.blockName === params.oldName) {
          exec.blockName = params.newName;
        }
      });
  },
  deleteBlock: (
    result: EnrichedExecutionResult,
    params: { deletedName: string },
  ) => {
    result.blockInfos && delete result.blockInfos[params.deletedName];
    result.lastOutputs && delete result.lastOutputs[params.deletedName];
  },
};
