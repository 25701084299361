import {
  extendsRestApiIntegrationPlugin,
  GraphQLActionConfiguration,
  RestApiIntegrationActionConfiguration,
  ApplicationScope,
  VariableType,
  VariableMode,
} from "@superblocksteam/shared";
import { call, select } from "redux-saga/effects";
import { evaluateActionBindings } from "legacy/sagas/EvaluationsShared";

import {
  getMergedDataTreeKeys,
  getMergedDataTree,
} from "legacy/selectors/dataTreeSelectors";
import { addBindingsToStringValue } from "pages/Editors/ApiEditor/ControlFlow/utils";

import { ApiTriggerType } from "store/slices/apis/types";
import { fastClone } from "utils/clone";

import { PLUGIN_INTEGRATIONS_MAP } from "utils/integrations";
import * as BackendTypes from "../backend-types";
import {
  createExpandedBindingValueObject,
  extractAttachedFiles,
} from "../control-flow/bindings";
import { computeBlockBindings } from "../control-flow/block-bindings";
import { selectV2UserAccessibleScope } from "../control-flow/control-flow-scope-selectors";
import { selectCachedControlFlowById } from "../control-flow/control-flow-selectors";
import { convertBlockToBackend } from "../control-flow/convert-block-to-backend";
import {
  BlockType,
  GenericBlock,
  VariableEntry,
  VariablesControlBlock,
} from "../control-flow/types";
import {
  selectAllInternalBindingsForBlock,
  selectTestDataForBlock,
  selectV2ApiById,
} from "../selectors";
import { TestDataType } from "../slice";

import { extractDataFromScope } from "./getV2ApiBlockDeps";

const VARIABLE_BLOCK_NAME = "SINGLE_BLOCK_VARIABLES_BLOCK";

export function* getUserGeneratedInputs({
  apiId,
  blockName,
}: {
  apiId: string;
  blockName: string;
}) {
  const rawInputs: Record<string, unknown> = {};
  const userGeneratedInputs: ReturnType<typeof selectTestDataForBlock> =
    yield select(selectTestDataForBlock, apiId, blockName);

  if (!userGeneratedInputs) {
    // we calculate test data before calling this function, so this should never happen
    return { inputs: rawInputs, attachedFiles: [] };
  }
  const variablesBlock: VariablesControlBlock = {
    name: VARIABLE_BLOCK_NAME,
    type: BlockType.VARIABLES,
    config: {
      variables: [] as VariableEntry[],
    },
  };

  const accessibleScope: ReturnType<typeof selectV2UserAccessibleScope> =
    yield select(selectV2UserAccessibleScope, apiId, blockName);
  const toEvaluate: Array<{ key: string; value: string }> = [];

  // Make the Global & theme object available only on UI apps
  const api: ReturnType<typeof selectV2ApiById> = yield select(
    selectV2ApiById,
    apiId,
  );
  // check v1 and v2 api definitions for the trigger type
  if (
    api?.actions?.triggerType === ApiTriggerType.UI ||
    api?.triggerType === ApiTriggerType.UI
  ) {
    toEvaluate.push({
      key: "Global",
      value: "Global",
    });

    toEvaluate.push({
      key: "theme",
      value: "theme",
    });
  }

  Object.entries(userGeneratedInputs).forEach(([key, input]) => {
    if (
      input.type === TestDataType.EXTERNAL ||
      input.type === TestDataType.BLOCK_OUTPUT
    ) {
      let value = !input.isDirty ? `${input.initialValue}` : `${input.value}`;
      value = value.replaceAll("\\", "\\\\");
      toEvaluate.push({
        key,
        value,
      });
    } else if (input.type === TestDataType.VARIABLE) {
      variablesBlock.config.variables.push({
        key: key.split(".")[0],
        type: VariableType.SIMPLE, // ???
        mode: VariableMode.READWRITE,
        value: addBindingsToStringValue(
          !input.isDirty ? `${input.initialValue}` : `${input.value}`,
        ),
      });
    }
  });

  // Add any variables from referenced variables that were not in userGeneratedInputs
  // this will happen if the block doesnt reference myVar.value but does reference myVar.set
  const internalBindings: ReturnType<typeof selectAllInternalBindingsForBlock> =
    yield select(selectAllInternalBindingsForBlock, apiId, blockName);
  const { referencedVariableBlocks } = internalBindings ?? {
    referencedVariableBlocks: [],
  };
  if (referencedVariableBlocks?.length) {
    const controlFlow: ReturnType<typeof selectCachedControlFlowById> =
      yield select((state) => selectCachedControlFlowById(state, apiId));
    if (controlFlow) {
      (referencedVariableBlocks ?? []).forEach((variableBlock) => {
        const block = controlFlow.blocks[variableBlock];
        if (block?.type === BlockType.VARIABLES) {
          const variables = (block as VariablesControlBlock).config.variables;
          variables.forEach((variable) => {
            if (!userGeneratedInputs[`${variable.key}.value`]) {
              variablesBlock.config.variables.push(variable);
            }
          });
        }
      });
    }
  }

  // get all the bindings referenced by variables
  if (variablesBlock.config.variables.length > 0) {
    let userAccessibleDataTree: ReturnType<typeof getMergedDataTree> =
      yield select(getMergedDataTree, ApplicationScope.PAGE);
    userAccessibleDataTree = {
      ...userAccessibleDataTree,
      ...(accessibleScope?.v2ComputedScope ?? {}),
    };

    const dataTreeKeys: string[] = yield select(
      getMergedDataTreeKeys,
      ApplicationScope.PAGE,
    );
    const plugins = PLUGIN_INTEGRATIONS_MAP;

    const identifiers = new Set([
      ...Object.keys(accessibleScope?.v2ComputedScope ?? {}),
      ...dataTreeKeys,
    ]);
    const { bindings }: { bindings: string[] } = yield call(
      computeBlockBindings,
      variablesBlock as GenericBlock,
      {
        identifiers,
        dataTree: userAccessibleDataTree,
        plugins,
      },
    );
    // put them at the beginning, so that user-defined inputs will always overwrite if there is a conflict
    toEvaluate.unshift(...bindings.map((b) => ({ key: b, value: b })));
  }

  const scopeValues = accessibleScope?.v2ComputedScope
    ? extractDataFromScope(accessibleScope?.v2ComputedScope)
    : undefined;
  // For the EXTERNAL and BLOCK_BINDNGS bindings, we need to evaluated them to get the actual value
  const result: { values: unknown[]; errors: unknown[] } = yield call(
    evaluateActionBindings,
    toEvaluate.map(({ value }) => value),
    ApplicationScope.PAGE, // TODO(API_SCOPE)
    undefined,
    scopeValues,
    undefined,
    true,
  );
  const { values: evaluationResults, errors } = result;
  if (errors.length) {
    return {
      errors,
      variablesBlock: undefined,
      inputs: undefined,
      attachedFiles: [],
    };
  }

  Array(Math.max(toEvaluate.length, evaluationResults.length))
    .fill(undefined)
    .forEach((_, i) => {
      rawInputs[toEvaluate[i].key] = evaluationResults[i];
    });
  const attachedFiles: BackendTypes.FileRequestParam[] = yield call(
    extractAttachedFiles,
    rawInputs,
  );

  const inputs = createExpandedBindingValueObject(rawInputs);
  return {
    variablesBlock: convertBlockToBackend(
      variablesBlock,
      // No children, this won't be used
      () => undefined as any as BackendTypes.Block,
    ),
    inputs,
    attachedFiles,
  };
}

export const processIntegrationConfig = (
  block: BackendTypes.Block,
  pluginId?: string,
) => {
  if (!block.step) return block;
  if (
    extendsRestApiIntegrationPlugin(pluginId) ||
    block.step.restapiintegration
  ) {
    const pluginKey = pluginId ?? "restapiintegration";
    const newBlock: typeof block = fastClone(block);
    if (!newBlock.step || !newBlock.step[pluginKey]) return block;
    const step = newBlock.step[
      pluginKey
    ] as RestApiIntegrationActionConfiguration;
    // remove things that are configured at the plugin-level
    step.urlBase = "";
    step.headers = (step.headers ?? []).filter(
      (header) => header.editable !== false,
    );
    step.params = (step.params ?? []).filter(
      (param) => param.editable !== false,
    );

    return newBlock;
  } else if (block.step.graphqlintegration) {
    const newBlock: typeof block = fastClone(block);
    if (!newBlock.step || !newBlock.step.graphqlintegration) return block;
    // remove things that are configured at the plugin-level
    const step = newBlock.step.graphqlintegration as GraphQLActionConfiguration;
    step.path = "";
    step.headers = (step.headers ?? []).filter(
      (header) => header.editable !== false,
    );
    return newBlock;
  }
  return block;
};
