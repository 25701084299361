import { combineReducers } from "redux";
import { eventsSlice } from "./events/slice";
import { applicationSlice } from "./slice";
import { stateVarsSlice } from "./stateVars/slice";
import { stateVarsMetaSlice } from "./stateVarsMeta/slice";
import { timersSlice } from "./timers/slice";
import { timersMetaSlice } from "./timersMeta/slice";

export const applicationReducer = combineReducers({
  /**
   * We are migrating all application-related legacy reducers, actions, selectors, and sagas into this folder.
   * Specifically, the minimum that we plan for is:
   * - currentApplication DSL
   * - currentPage DSL
   * - timers
   */
  [stateVarsSlice.name]: stateVarsSlice.reducer,
  [stateVarsMetaSlice.name]: stateVarsMetaSlice.reducer,
  [timersSlice.name]: timersSlice.reducer,
  [timersMetaSlice.name]: timersMetaSlice.reducer,
  [applicationSlice.name]: applicationSlice.reducer,
  [eventsSlice.name]: eventsSlice.reducer,
});
